export default {
  MAX_CARD_ALLOWED: 99,
  MAX_ACTIVE_CARD: 6,
  MAX_CARD_LIMIT: 'MAX_CARD_LIMIT',
  MAX_ACTIVE_CARD_LIMIT: 'MAX_ACTIVE_CARD_LIMIT',
  CARD_TYPE: {
    ACTIVE: 'active',
    INACTIVE: 'inactive'
  }
};
