// @ngInject
function acpVirtualCardsClient(webapiResource) {
  var client = {};

  client.listAllVirtualCards = webapiResource({
    path: '/v1/cards/virtual',
    method: 'GET'
  });

  client.getIndividualCard = webapiResource({
    path: '/v1/cards/virtual/:id',
    method: 'GET'
  });

  client.closeVirtualCard = webapiResource({
    path: '/v1/cards/virtual/:id',
    method: 'DELETE'
  });

  client.createVirtualCard = webapiResource({
    path: '/v1/cards/virtual',
    method: 'PUT'
  });

  client.updateNickname = webapiResource({
    path: '/v1/cards/virtual/nickname',
    method: 'PUT'
  });

  client.getAllPhysicalCards = webapiResource({
    path: '/v1/cards',
    method: 'GET'
  });

  return client;
}

export default acpVirtualCardsClient;
