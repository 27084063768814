// @ngInject
function acpVirtualCardsModel(
  acpVirtualCardsClient,
  nsProperty,
  ACP_VIRTUAL_CARDS_CONSTANTS,
  content,
  nsInPageFlow,
  acpCoreDispatcher,
  $q,
  acpCvcAuthenticationModel,
  nsDate,
  nsUtil
) {
  var model = {};
  var physicalCardPromise = null;

  model.virtualCardsList = nsProperty.create();
  model.primaryCardsList = nsProperty.create();
  model.isCvcAuthed = nsProperty.create();

  function resetVirtualInfo() {
    model.virtualCardsList.reset();
    return getAllVirtualCards().then(function () {
      acpCoreDispatcher.virtualCard.virtualCardsUpdate.emit();
    });
  }

  function getAllVirtualCards() {
    var deferred = $q.defer();
    if (model.virtualCardsList.getValue()) {
      deferred.resolve(model.virtualCardsList.getValue());
    } else {
      acpVirtualCardsClient.listAllVirtualCards().then(
        function (data) {
          var cards = data.cards.map(function (card) {
            return convertStringToDateObject(card);
          });
          model.virtualCardsList.set(cards);
          deferred.resolve(cards);
        },
        function () {
          deferred.reject();
        }
      );
    }
    return deferred.promise;
  }

  function getFilteredCard(type, cardsList) {
    var cards = cardsList ? cardsList : model.virtualCardsList.getValue();
    const filteredCards = [];
    if (type === ACP_VIRTUAL_CARDS_CONSTANTS.CARD_TYPE.ACTIVE) {
      for (let card of cards) {
        const maskedCard = decryptCardData(false, card);
        maskedCard.masked = true;
        if (card.status === 'activated') {
          filteredCards.push(maskedCard);
        }
      }
    } else {
      for (let card of cards) {
        const maskedCard = decryptCardData(false, card);
        maskedCard.masked = true;
        if (card.status !== 'activated') {
          filteredCards.push(maskedCard);
        }
      }
    }

    return filteredCards;
  }

  function canUserAddCard() {
    if (
      model.virtualCardsList.getValue().length >=
      ACP_VIRTUAL_CARDS_CONSTANTS.MAX_CARD_ALLOWED
    ) {
      return {
        status: false,
        reason: ACP_VIRTUAL_CARDS_CONSTANTS.MAX_CARD_LIMIT
      };
    } else if (
      getFilteredCard(ACP_VIRTUAL_CARDS_CONSTANTS.CARD_TYPE.ACTIVE).length >=
      ACP_VIRTUAL_CARDS_CONSTANTS.MAX_ACTIVE_CARD
    ) {
      return {
        status: false,
        reason: ACP_VIRTUAL_CARDS_CONSTANTS.MAX_ACTIVE_CARD_LIMIT
      };
    } else {
      return { status: true, reason: '' };
    }
  }

  function getIndividualCard(id) {
    const cardList = model.virtualCardsList.getValue();
    const card = cardList.find((card) => card.id === id);
    return card;
  }

  function closeVirtualCard(id) {
    return acpVirtualCardsClient.closeVirtualCard({ id: id });
  }

  function decryptCardData(showCvcPan, data) {
    var finalObject = {};
    if (!data) {
      return finalObject;
    }
    finalObject = { ...data };
    if (showCvcPan) {
      finalObject.finalPan =
        data.pan && data.pan.replace(/(\d{4})/g, '$1 ').trim();
      finalObject.panLastFourDigit =
        data.pan && data.pan.substring(data.pan.length - 4, data.pan.length);
      finalObject.finalCvc = data.cvc;
      finalObject.finalExpDate = data.expiration_date;
    } else {
      finalObject.finalPan =
        data.obscured_pan && data.obscured_pan.replace(/-/g, ' ');
      finalObject.panLastFourDigit =
        data.obscured_pan &&
        data.obscured_pan.substring(
          data.obscured_pan.length - 4,
          data.obscured_pan.length
        );
      finalObject.finalCvc = 'XXX';
      finalObject.finalExpDate = 'XX/XX';
    }
    return finalObject;
  }

  function formatCardDetailData(data) {
    //ignored time-zone info for date consistencies.
    data.activation_date_obj = new Date(
      nsDate.fromApi(data.activation_date.split(' ')[0])
    );
    data.expiration_date_obj = new Date(
      nsDate.fromApi(data.expiration_date.split(' ')[0])
    );
    return data;
  }

  function getInflow(type, tmpl, title) {
    if (type === 'open') {
      nsInPageFlow.open({
        template: tmpl,
        flowTitle: title
      });
    } else {
      nsInPageFlow.push({
        template: tmpl,
        flowTitle: title
      });
    }
  }

  function closeInflow() {
    if (nsInPageFlow.isOpen()) {
      nsInPageFlow.close();
    }
  }

  function updateNickname(nickname, id) {
    return acpVirtualCardsClient.updateNickname({
      nick_name: nickname,
      id: id
    });
  }

  function createVirtualCard(nickname) {
    var data = '';
    if (nickname) {
      data = { nick_name: nickname };
    }
    return acpVirtualCardsClient.createVirtualCard(data);
  }

  function getContent(key, file) {
    return content.getKey(key, file).then(function (data) {
      return data.copy;
    });
  }

  function getAllPhysicalCards(resetPrimaryCardsList) {
    if (resetPrimaryCardsList) {
      //reset primary card list if it's called by section
      model.primaryCardsList.reset();
    }
    if (physicalCardPromise && !physicalCardPromise.$$state.status) {
      return physicalCardPromise;
    }
    var deferred = $q.defer();
    if (model.primaryCardsList.getValue()) {
      deferred.resolve(model.primaryCardsList.getValue());
    } else {
      acpVirtualCardsClient.getAllPhysicalCards().then(function (card) {
        var cards = card.cards.map(function (card) {
          return convertStringToDateObject(card);
        });
        model.primaryCardsList.set(cards);
        deferred.resolve(cards);
      });
    }
    physicalCardPromise = deferred.promise;
    return physicalCardPromise;
  }

  function isCvcAuthed(data) {
    if (data) {
      model.isCvcAuthed.set(data.cvc);
    }
    return model.isCvcAuthed.getValue();
  }

  function setCvcAuthedValue() {
    acpCvcAuthenticationModel.get().then(function (data) {
      model.isCvcAuthed.set(data.cvc);
    });
  }

  function convertStringToDateObject(card) {
    if (nsUtil.isDefined(card.expiration_date)) {
      card.expiration_date = formatDate(card.expiration_date);
    }
    if (nsUtil.isDefined(card.activation_date)) {
      card.activation_date = formatDate(card.activation_date);
    }
    if (nsUtil.isDefined(card.lost_date)) {
      card.lost_date = formatDate(card.lost_date);
    }
    if (nsUtil.isDefined(card.stolen_date)) {
      card.stolen_date = formatDate(card.stolen_date);
    }
    return card;
  }

  function formatDate(date) {
    var expDateSplit = date.split(' ')[0];
    return new Date(nsDate.fromApi(expDateSplit));
  }

  return {
    getAllVirtualCards: getAllVirtualCards,
    getFilteredCard: getFilteredCard,
    resetVirtualInfo: resetVirtualInfo,
    canUserAddCard: canUserAddCard,
    getIndividualCard: getIndividualCard,
    closeVirtualCard: closeVirtualCard,
    decryptCardData: decryptCardData,
    formatCardDetailData: formatCardDetailData,
    createVirtualCard: createVirtualCard,
    getContent: getContent,
    getInflow: getInflow,
    closeInflow: closeInflow,
    getAllPhysicalCards: getAllPhysicalCards,
    updateNickname: updateNickname,
    isCvcAuthed: isCvcAuthed,
    setCvcAuthedValue: setCvcAuthedValue
  };
}

export default acpVirtualCardsModel;
